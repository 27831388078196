<template>
  <div class="px-3 py-2">
    <v-form ref="forcar" lazy-validation>
      <b-sidebar
        id="add_convert"
        aria-labelledby="sidebar-no-header-title"
        no-header
        backdrop
        width="700px"
        style="direction: ltr"
        right
        title="إضافة ملاحظة"
        shadow
      >
        <template #default="{ hide }">
          <div class="m-1 text-start" style="direction: rtl">
            <div
              style="
                width: 120px;
                margin-right: 10px;
                background: #000;
                color: #fff;
                border-radius: 5px;
              "
              class="btn-primary btn-sm text-center"
            >
              <span>تحويل عميل</span>
            </div>
            <div
              @click="hide"
              id="hideaddConvert"
              style="
                width: 100px;
                text-align: center;
                float: left;
                margin-left: 5px;
                margin-top: -25px;
                cursor: pointer;
              "
              class="btn-danger btn-sm"
            >
              <span>{{ lang.close }}</span>
            </div>
          </div>
          <v-divider></v-divider>
          <v-row style="direction: rtl" class="m-2">
            <v-col cols="12" md="6" sm="12">
              <label>اختر المنتج</label>
              <b-input-group>
                <b-form-select
                  class="selborder"
                  v-model="product"
                  :options="getProds"
                ></b-form-select>
                <b-input-group-append style="margin-right: -20px; margin-top: 5px">
                  <i class="fas fa-arrow-down"></i>
                </b-input-group-append>
              </b-input-group>
            </v-col>
            <v-col cols="12" md="6" sm="12">
              <label>المبلغ المتوقع الحصول عليه من العميل</label>
              <b-form-input
                class="inborder"
                v-model="expected_amount"
                value=""
                required
              ></b-form-input>
            </v-col>
          </v-row>
        </template>
        <template #footer="">
          <div class="d-flex text-light align-items-center px-3 py-2">
            <b-button
              type="button"
              @click="addTasks()"
              variant="success"
              class="ma-2"
              style="width: 100px"
              >إضافة</b-button
            >
          </div>
        </template>
      </b-sidebar>
    </v-form>
  </div>
</template>

<script>
import axios from "axios";
import { SnotifyPosition } from "vue-snotify";
export default {
  data() {
    return {
      customerid: 0,
      expected_amount: "",
      optionsS: [],
      product: 1,
    };
  },
  computed: {
    lang: function () {
      if (this.$cookies.get(this.$store.state.LangCooki) != null) {
        if (this.$cookies.get(this.$store.state.LangCooki) == "en") {
          return this.$store.state.lang.en;
        } else {
          return this.$store.state.lang.ar;
        }
      } else if (this.$store.state.deflang == "en") {
        return this.$store.state.lang.en;
      } else {
        return this.$store.state.lang.ar;
      }
    },
    getProds: function () {
      let t = [];
      for (let i = 0; i < this.optionsS.length; i++) {
        t.push({
          text: this.optionsS[i].name_ar,
          value: this.optionsS[i].value,
        });
      }
      return t;
    },
  },
  methods: {
    addTasks() {
      if (this.expected_amount == "" || this.expected_amount == 0) {
        document.getElementById("hideaddConvert").click();
        let message = "يرجى ادخال رقم في المبلغ المتوقع تحصيله من العميل";
        this.$snotify.error(message, "تنبيه", {
          timeout: 1000000,
          showProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          position: SnotifyPosition.centerCenter,
          buttons: [
            {
              text: this.lang.close,
              action: (toast) => {
                this.$snotify.remove(toast.id);
              },
            },
          ],
        });
        return false;
      }
      const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
      post.append("type", "convertToCurrent");
      post.append("auth", this.$cookies.get(this.$store.state.COOKIEPhase));
      post.append("data[customerid]", this.customerid);
      post.append("data[expected_amount]", this.expected_amount);
      post.append("data[product]", this.product);
      axios.post(this.$SAMCOTEC.r_path, post).then((response) => {
        if (response && response.data) {
          document.getElementById("hideaddConvert").click();
          let message = "تم تحويل العميل بنجاح";
          this.$snotify.error(message, "تنبيه", {
            timeout: 1000000,
            showProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            position: SnotifyPosition.centerCenter,
            buttons: [
              {
                text: this.lang.close,
                action: (toast) => {
                  this.$snotify.remove(toast.id);
                },
              },
            ],
          });
        }
      });
    },
    getStatic() {
      const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
      post.append("type", "getStaticOptions");
      post.append("auth", this.$cookies.get(this.$store.state.COOKIEPhase));
      post.append("lang", this.$cookies.get(this.$store.state.LangCooki));
      post.append("data[phrase]", "convertProducts");
      axios.post(this.$store.state.SAMCOTEC.r_path, post).then((response) => {
        // // console.log("i am here",response.data)
        this.optionsS = response.data.results.data;
        // // console.log("i am here",this.optionsS);
      });
    },
  },
  created() {
    // this.getCurrent();
    // this.getStatus('taskStatus');
    this.getStatic("convertProducts");
  },
};
</script>
